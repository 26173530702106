import axios, { AxiosResponse } from 'axios'

const fetchUrl = async <T extends {}>(url: string): Promise<AxiosResponse<T>> => {
  const result = await axios.get<T>(url)

  return result
}

const Api = { fetchUrl }

export default Api
