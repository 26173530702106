import * as React from 'react'
import { Map, MapProps, Marker, Tooltip } from 'react-leaflet'
import * as L from 'leaflet'
import { GestureHandling } from 'leaflet-gesture-handling'
import WorldMap from './WorldMap'
import { LatLngBoundsExpression } from 'leaflet'
import { navigate } from 'gatsby'
import { useProjectData, ProjectPin } from '../../../hooks/allProjects'
import { LocaleContext } from '../../layouts'

import 'leaflet/dist/leaflet.css'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'
import styled from '../../../styles/styled'

type ClosableTooltip = L.Tooltip & { _close: () => void }

const StyledMapWrap = styled.map`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black !important;
  z-index: 1;
`
if (typeof window !== 'undefined') {
  // @ts-ignore
  delete L.Icon.Default.prototype._getIconUrl

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('../../../images/marker-icon-2x.png'),
    iconUrl: require('../../../images/marker-icon.png'),
    shadowUrl: require('../../../images/marker-shadow.png')
  })

  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)
}

class MyMap extends Map<MapProps & { gestureHandling: boolean; gestureHandlingText: {} }, L.Map> {}
const StyledMap = styled(MyMap)`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.dark} !important;
`

type Props = {
  currentPath: string
}

const mercatorBounds: LatLngBoundsExpression = [
  [-90, -180.0],
  [90, 180.0]
]

const ProjectMap: React.FC<Props> = ({ currentPath }) => {
  const mapRef = React.createRef<StyledMap>()
  const { locale } = React.useContext(LocaleContext)
  const projects = useProjectData(locale)

  const fitMap = () => {
    mapRef.current ? mapRef.current.leafletElement.fitBounds(mercatorBounds, { padding: [30, 30] }) : null
  }

  const handleMarkerClick = (project: ProjectPin) => {
    var url = project.detailUrl
    if (locale === 'de') {
      url = 'de/' + url
    }
    navigate(url)

    if (mapRef.current) {
      mapRef.current.leafletElement.eachLayer((layer: ClosableTooltip) => {
        if (layer.options.pane === 'tooltipPane') {
          layer._close()
        }
      })
    }
  }

  // only render project-markers if path is neither home nor /intro
  const isProjectSite = currentPath && currentPath.includes('projects')

  const projectIcon = (project: string) => {
    return L.divIcon({
      className: `marker-${project}`,
      html: `
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <g>
      <g>
        <path d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035
          c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,469.729
          c-55.847-66.338-152.035-197.217-152.035-284.301c0-83.834,68.202-152.036,152.035-152.036s152.035,68.202,152.035,152.035
          C408.034,272.515,311.861,403.37,256,469.729z"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M256,92.134c-51.442,0-93.292,41.851-93.292,93.293s41.851,93.293,93.292,93.293s93.291-41.851,93.291-93.293
          S307.441,92.134,256,92.134z M256,245.328c-33.03,0-59.9-26.871-59.9-59.901s26.871-59.901,59.9-59.901s59.9,26.871,59.9,59.901
          S289.029,245.328,256,245.328z"/>
      </g>
    </g>
    </svg>
    `,
      iconSize: L.point(60, 60),
      iconAnchor: L.point(30, 60)
    })
  }

  if (typeof window === 'undefined') {
    return null
  }

  return (
    <React.Fragment>
      <StyledMapWrap>
        <StyledMap
          zoomSnap={0}
          zoomDelta={1}
          whenReady={() => fitMap()}
          onresize={() => fitMap()}
          zoomControl={false}
          zoom={1}
          bounds={mercatorBounds}
          maxBounds={mercatorBounds}
          ref={mapRef}
          className="project-map"
          gestureHandling={true}
          gestureHandlingText={{
            touch: 'Zwei-Finger Geste zum zoomen',
            scroll: 'Ctrl + Scroll zum zoomen',
            scrollMac: '\u2318 + scroll zum zoomen'
          }}
        >
          {isProjectSite &&
            projects.map((project, key) => (
              <Marker
                key={key}
                position={[project.geoCoordinate.latitude, project.geoCoordinate.longitude]}
                icon={projectIcon(project.project)}
                onClick={() => handleMarkerClick(project)}
                class={project.project}
              >
                <Tooltip direction="top" offset={[0, -49]} opacity={1}>
                  {project.title}
                </Tooltip>
              </Marker>
            ))}
          <WorldMap />
        </StyledMap>
      </StyledMapWrap>
    </React.Fragment>
  )
}

export default ProjectMap
