import { useStaticQuery, graphql } from 'gatsby'
import { AllProjectsQuery } from '../graphql-types'

export type ProjectPin = {
  project: string
  title: string
  geoCoordinate: {
    latitude: number
    longitude: number
  }
  detailUrl: string
}

export const useProjectData = (locale: string): ProjectPin[] => {
  const { allMdx } = useStaticQuery<AllProjectsQuery>(
    graphql`
      query AllProjects($locale: String) {
        allMdx(
          filter: {
            frontmatter: { template: { eq: "project-info" } }
            fields: { locale: { eq: $locale } }
            fileAbsolutePath: { regex: "/info/" }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                keyFacts {
                  climate
                  coordinates
                  country
                  keywords
                  name
                  region
                  longitude
                  latitude
                }
              }
              fields {
                locale
                isDefault
                project
              }
              parent {
                ... on File {
                  relativeDirectory
                }
              }
            }
          }
        }
      }
    `
  )

  const projects: ProjectPin[] = allMdx.edges
    .filter(edge => edge.node.fields?.locale === locale)
    .map(edge => ({
      project: edge.node.fields?.project,
      title: edge.node.frontmatter?.keyFacts?.country ?? 'no name',
      geoCoordinate: {
        latitude: edge.node.frontmatter?.keyFacts?.latitude ?? 0.0,
        longitude: edge.node.frontmatter?.keyFacts?.longitude ?? 0.0
      },
      detailUrl: edge.node.parent?.relativeDirectory
    }))

  return projects
}
