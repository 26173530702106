import * as React from 'react'
import { useEffect, useState } from 'react'
import Api from '../../../api/Api'
import { GeoJsonObject } from 'geojson'
import * as topojson from 'topojson'
import { GeoJSON } from 'react-leaflet'

type Props = {}

const WorldMap: React.FC<Props> = () => {
  const [geoJsonData, setGeoJsonData] = useState<GeoJsonObject>()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const fetchMapData = async () => {
        const response = await Api.fetchUrl<TopoJSON.Topology>('/topojson/countries.topo.json')
        const topoJsonObject = response.data
        const geoJson: GeoJsonObject = topojson.feature(topoJsonObject, topoJsonObject.objects['countries.geo'])
        setGeoJsonData(geoJson)
      }
      fetchMapData().then()
    }
  }, [])

  if (typeof window === 'undefined') {
    return null
  }

  return (
    <React.Fragment>
      {geoJsonData && (
        <GeoJSON data={geoJsonData} fillColor="black" stroke={true} color="#00896D" opacity={0.9} fillOpacity={1.0} weight={1} />
      )}
    </React.Fragment>
  )
}

export default WorldMap
